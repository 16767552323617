/* ==================== */
/* COMMON CSS */
/* ==================== */

@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@300;400;500;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap'); */

:root {
  /* Colors */
  --main-color: #5a7f92;
  --secondary-color: #414956;
  --black-color: #1B1B1B;
  --grey-color: #666666;
  --light-grey-color: #999999;
  --white-color: #fff;

  --body-bg: #F8FCFE;
  --main-color-hover: #7b949e;

  /* Fonts */
  --main-font: 'Alegreya Sans', sans-serif;
  /* --heading-font: 'Dancing Script', cursive; */
}

html, body {
  font-family: var(--main-font);
  color: var(--grey-color);
  background-color: var(--body-bg);
  scroll-behavior: smooth;
}

img { max-width: 100%; }

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}