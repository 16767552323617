.contact-us .inner-container {display: flex;flex-wrap: wrap;}
.contact-us .col-left { width: 35%;}
.contact-us .col-right { width: 65%;}
.contact-us h3 { font-size: 40px; font-weight: 700; color: var(--secondary-color); margin-bottom: 40px; }
.contact-us .contact-info > div { display: flex; gap: 15px; align-items: center; }
.contact-us .contact-info > div:not(:last-child) { margin-bottom: 25px; }
.contact-us .contact-info .icon { background-color: rgba(90, 127, 146, 0.1); width: 60px; height: 60px; padding: 15px; border-radius: 50%; line-height: 1; display: inline; }
.contact-us .contact-info img { width: 30px; height: 30px; display: block; }
.contact-us .contact-info .text { font-size: 20px; font-weight: 500; line-height: 30px; width: calc(100% - 75px); }
.contact-us .contact-info a { text-decoration: none; color: inherit; }

.contact-us .main-button { display: block; width: 180px; background-color: var(--main-color); border-radius: 100px; font-family: var(--main-font); font-size: 18px; line-height: 26px; padding: 12px 25px; text-transform: none; font-weight: 500; margin-bottom: 24px; }
.contact-us .main-button:hover { background-color: var(--main-color-hover); }

.contact-us .contact-form .ant-input { padding: 15px 11px; font-family: var(--main-font); font-size: 18px; line-height: 30px; color: var(--grey-color); }


@media(max-width: 1536px) {
    .contact-us .contact-info img { width: 25px; height: 25px; }
    .contact-us .contact-info .icon { width: 55px; height: 55px; }
}
@media(max-width: 1440px) {
    .contact-us h3 { font-size: 36px; max-width: 250px; margin-bottom: 30px; }
    .contact-us .contact-info img { width: 20px; height: 20px; }
    .contact-us .contact-info .icon { width: 50px; height: 50px; }
    .contact-us .contact-info .text { font-size: 18px; line-height: 26px; }
    .contact-us .contact-form .ant-input{ padding: 11px; font-size: 16px; }
}
@media(max-width: 768px) {
    .contact-us h3 { font-size: 32px;}
    .contact-us [class*="col-"]{width: 100%;}
    .contact-us .col-left{margin-bottom: 20px;}
    .contact-us h3{max-width: none; margin-bottom: 15px;}
}
@media(max-width: 600px) {
    .contact-us .contact-info > div { gap: 10px; }
    .contact-us .contact-info .icon {
        padding: 10px;
        width: 38px;
        height: 38px;
    }
    .contact-us .contact-info img{width: 18px;height: 18px;}
    .contact-us .contact-info .text {
        font-size: 16px;
        line-height: 22px;
    }
}
@media(max-width: 425px) {
    .contact-us h3 { font-size: 28px;}
}