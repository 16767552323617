.about-us .about-img { height: 280px; width: 444px; object-fit: cover; border-radius: 20px; }
.about-us .col-text { height: 100%; display: grid; place-content: center; }
.about-us .col-text h3 { color: var(--black-color); font-size: 24px; font-weight: 500; line-height: 34px; margin-bottom: 10px; }
.about-us .col-text p { font-size: 20px; font-weight: 400; line-height: 30px; margin-bottom: 0; }
.about-us .mission,
.about-us .vision { margin-top: 45px; }
.about-us .mission img,
.about-us .vision img { height: 50px;width: 50px;margin-bottom: 10px; }
.about-us .mission h4,
.about-us .vision h4 { color: var(--black-color); font-size: 24px; line-height: 34px; font-weight: 500; margin-bottom: 10px; }
.about-us .mission p,
.about-us .vision p { font-size: 18px; line-height: 28px; font-weight: 400; margin-bottom: 0; }


@media(max-width: 1366px) {
    .about-us .mission,
    .about-us .vision { margin-top: 30px; }
}

@media(max-width: 1100px) {
    .about-us .about-img { height: 270px;}
}
@media(max-width: 1024px) {
    .about-us .about-img { height: 250px;}
    .about-us .mission,
    .about-us .vision { margin-top: 20px; }
    .about-us .col-text h3{font-size: 22px;line-height: 30px;}
    .about-us .mission h4, .about-us .vision h4{font-size: 22px;line-height: 30px;margin-bottom: 5px;}
    .about-us .col-text p { font-size: 18px; line-height: 28px; }
    .about-us .mission img, .about-us .vision img { height: 45px; width: 45px; margin-bottom: 5px; }
    .about-us .mission p,
    .about-us .vision p { font-size: 16px; line-height: 26px;}
    .about-us img{margin-bottom: 15px;}
}
@media(max-width: 768px) {
    .about-us .about-img { height: 200px;}
}
@media(max-width: 600px) {
    .about-us .about-img { height: 280px;}
}
@media(max-width: 425px) {
    .about-us .about-img { height: 210px;}
}
@media(max-width: 375px) {
    .about-us .about-img { height: 175px;}
}
@media(max-width: 375px) {
    .about-us .about-img { height: 140px;}
}