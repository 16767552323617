
.profile .profile-card { background-color: #fff; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; border-radius: 10px; padding: 40px 20px;height: 100%;}
.profile .profile-card .ant-upload-wrapper .ant-upload-select {width: 120px; height: auto; aspect-ratio: 1/1;}
.profile .profile-card .ant-upload-wrapper .ant-upload-select .ant-upload {width: 100%;}
.profile .profile-card h3 { font-size: 24px; color: var(--secondary-color); font-weight: 500; text-align: center; margin-bottom: 20px; }
.profile .profile-card .links { border-top: 1px solid #e1e1e1; display: flex; flex-direction: column; padding-top: 20px; }
.profile .profile-card .links a { display: flex; padding: 11px 17px; border-radius: 5px; gap: 10px; color: var(--light-grey-color); text-decoration: none; font-size: 18px; font-weight: 500; }
.profile .profile-card .links a img{ height: 24px; width: 24px; }
.profile .profile-card .links a img:nth-child(2){ display: none; }
.profile .profile-card .links a.active { background-color: #E8F0F3; color: var(--main-color); }
.profile .profile-card .links a.active img:nth-child(1){ display: none; }
.profile .profile-card .links a.active img:nth-child(2){ display: inline; }
.profile .profile-form { background-color: #fff; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; border-radius: 10px; }
.profile .profile-form .title { padding: 20px 30px; color: var(--secondary-color); border-bottom: 1px solid #e1e1e1; font-size: 22px; font-weight: 500; margin-bottom: 0;}


.profile .profile-form .profile-form-body { padding: 45px 35px; max-width: 500px; margin: auto; }

.profile .profile-form .actions {display: flex; flex-wrap: wrap; align-items: center;gap: 25px;}
.profile .profile-form .main-button{ display: block; min-width: 180px; background-color: var(--main-color);border-radius: 100px;font-family: var(--main-font);font-size: 18px;line-height: 26px; padding: 12px 25px; text-transform: none; font-weight: 500;}
.profile .profile-form .main-button:hover {background-color: var(--main-color-hover);}
.profile .profile-form .actions a{text-decoration: none;color: var(--light-grey-color);font-size: 18px;font-weight: 500;}
.profile .profile-form .ant-input-affix-wrapper {padding: 15px;}
.site-form-item-icon{color: var(--grey-color);width: 16px;height: 16px;}

.profile .image-uploader{display: flex;justify-content: center;}

.profile .ant-input-prefix {margin-right: 15px;}
.profile .ant-input-prefix > img,
.profile .ant-input-prefix .site-form-item-icon,
.profile .ant-input-prefix svg{width: 20px;height: 20px;}
.profile .ant-input-affix-wrapper > .ant-input-lg {font-size: 18px;font-family: var(--main-font);color: #1b1b1b;}


@media(max-width: 1536px) {
    .profile .profile-card{ padding: 30px 20px;}
    .profile .profile-card .ant-upload-wrapper .ant-upload-select {width: 100px;}
    .profile .profile-card .links a { padding: 10px 15px; font-size: 17px; }
    .profile .profile-card h3 { font-size: 22px; margin-bottom: 15px; }
    .profile .profile-form .title{font-size: 20px; padding: 18px 20px;}
    .profile .profile-form .main-button {padding: 8px 25px;font-size: 18px;}
}
@media(max-width: 1440px) {
    .profile .profile-form .ant-input-affix-wrapper { padding: 11px 15px; }
    .profile .ant-input-prefix > img, .profile .ant-input-prefix .site-form-item-icon, .profile .ant-input-prefix svg { width: 18px; height: 18px; }
    .profile .ant-input-prefix { margin-right: 10px; }
}
@media(max-width: 1366px) {
    .profile .profile-card{ padding: 20px 15px;}
    .profile .profile-card .ant-upload-wrapper .ant-upload-select {width: 85px;}
    .profile .profile-form .actions{gap: 15px;}
    .profile .profile-card .links a { padding: 10px 12px; font-size: 16px; }
    .profile .profile-form .title{font-size: 18px;padding: 15px;}
    .profile .profile-form .main-button {min-width: 100px;font-size: 16px;line-height: 24px;}
    .profile .profile-form .actions a {font-size: 16px; line-height: 24px;}
}
@media(max-width: 1280px) {
    .profile .profile-form .main-button{padding: 8px 18px;}
}
@media(max-width: 600px) {
    .profile .profile-card{ margin-bottom: 15px; height: auto;}
    .profile .profile-form .profile-form-body { padding: 20px 15px;}
    .profile .profile-form .actions{gap: 10px;}
    .profile .profile-form .main-button { padding: 8px 15px; }
}