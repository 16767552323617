.pageHeader {height: 350px;padding-top: 75px;border-radius: 10px;background: #E8F0F3 url('../../../public/assets/pageHeader-bg.png') center no-repeat;background-size: cover;}
.pageHeader .title{text-align: center;font-size: 40px; font-weight: 700;color: var(--secondary-color);}

@media(max-width: 1440px) {
    .pageHeader .title { font-size: 36px; }
}
@media(max-width: 768px) {
    .pageHeader{height: auto; padding: 50px 0;}
    .pageHeader .title { font-size: 32px; }
}
@media(max-width: 425px) {
    .pageHeader{padding: 40px 0;}
    .pageHeader .title { font-size: 28px; }
}