/* ==================== */
/* USER ADMIN LAYOUT CSS */
/* ==================== */
.admin-layout { display: flex; position: relative; }
.admin-layout > .col-left { width: 16%; transition: all .2s ease-in-out; position: fixed; z-index: 1; }
.admin-layout > .col-right { width:  84%; position: relative; transition: all .2s ease-in-out; margin-left: auto; }

.admin-layout > .col-right .right {display: flex; flex-direction: column;min-height: 100vh;}

/* Leftbar */
.admin-layout .left{ position: relative; z-index: 1; display: flex; justify-content: space-between; flex-direction: column; background: var(--body-bg); height: 100vh; padding: 75px 30px 35px; width: 100%; overflow-y: auto; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; }
.admin-layout .left .admin-logo .logo{ background-color: #F8FCFE; width: 190px; aspect-ratio: 1/1; display: grid; place-items: center; border-radius: 100%; text-align: center; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; margin: auto; }
.admin-layout .left .admin-logo .logo::before { content: ''; position: absolute; z-index: 0; height: 170px; width: 100%; top: 0; left: 0; right: 0; z-index: -1; background: url('../../public/assets/admin-sidebarBg.png') top center no-repeat; background-size: cover; }
.admin-layout .left .admin-logo img { width: 140px; max-width: 90%; }
.admin-layout .left .admin-logo svg { margin-top: 30px;}

.admin-layout .left ul{padding-left: 0; margin-bottom: 0; list-style: none; }
.admin-layout .left ul li{padding: 0; margin-bottom: 15px; transition: 0.85s all ease;}
.admin-layout .left ul li a{ display: flex; align-items: center; font-size: 20px; line-height: 30px; color: var(--light-gray); font-weight: 500; text-decoration: none; text-transform: capitalize; padding: 11px 20px; transition: 0.2s all ease;}
.admin-layout .left ul li a p {margin-bottom: 0px;}
.admin-layout .left ul li a img{margin-right: 18px; margin-bottom: 0px; width: 24px; height: 24px;}
.admin-layout .left ul li a img.hover-image{display: none;}
.admin-layout .left ul li:hover a{color: var(--main-color);}
.admin-layout .left ul li:hover a img,
.admin-layout .left ul li.active a img{display: none;}
.admin-layout .left ul li:hover a img.hover-image{display: inline !important;}
.admin-layout .left ul li.active a{color: var(--main-color);}
.admin-layout .left ul li.active a img{display: none;}
.admin-layout .left ul li.active a img.hover-image{display: inline !important;}

.admin-layout .left .cpy { font-size: 18px; line-height: 30px; color: var(--grey-color); margin-bottom: 0; }


/* SideBar */
.admin-layout .sidebar.col-left { width: 5%; }
.admin-layout .sidebar.col-right { width: 95%; }
.admin-layout .sidebar .left .admin-logo .logo {display: none;}
.admin-layout .sidebar .left .admin-logo .logo::before {content: none;}
.admin-layout .sidebar .left ul { margin: auto ;}
.admin-layout .sidebar .left .cpy { display: none; }

.admin-layout .sidebar .left ul li a {padding: 11px 0;font-size: 0;}
.admin-layout .sidebar .left .admin-logo svg,
.admin-layout .sidebar .left ul li a img {margin: auto;}

/* LeftBar Scroll */
.admin-layout .left { overflow-x: auto; }
.admin-layout .left::-webkit-scrollbar { width: 7px; border-radius: 10px; }
.admin-layout .left::-webkit-scrollbar-track { background-color: #e1e1e1; border-radius: 10px; }
.admin-layout .left::-webkit-scrollbar-thumb { transition: all .2s ease-in-out; background-color:  #bbb; border-radius: 10px; }
.admin-layout .left::-webkit-scrollbar-thumb:hover { background-color: #aaa; }

/* Admin Container */
.admin-container { margin: 40px 25px; }
.inner-container { padding: 30px; width: 966px; max-width: 90%; margin: -190px auto 0; background: #fff; border-radius: 20px; animation: fadeIn 1s ease-in-out forwards; }

/* Admin Footer */
.admin-layout .admin-footer { padding: 20px 30px; display: flex;justify-content: end; color: var(--grey-color);font-size: 18px;line-height: 30px; margin-top: auto;}
.admin-layout .admin-footer .links a{text-decoration: none; color: inherit;}
.admin-layout .admin-footer .links a:hover {color: var(--main-color);}

.ant-input-outlined:hover{
    border-color: var(--main-color);
}
.ant-input-outlined:focus{
    border-color: var(--main-color);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media (max-width: 1680px) {
    .admin-layout .left .admin-logo .logo {width: 175px;height: 175px;}
    .admin-layout .left .admin-logo .logo img{width: 125px;}
    .admin-layout .left .admin-logo .logo::before {height: 160px;}

    .admin-layout .left ul li a{padding: 5px 10px; font-size: 18px; }
    .admin-layout .left ul li a img { margin-right: 15px; width: 22px; height: 22px; }
    .admin-layout .left ul li {margin-bottom: 10px;}
    
    .admin-layout .left .cpy {font-size: 16px;}
}
@media (max-width: 1536px) {
    .admin-layout .left .admin-logo .logo{width: 140px;height: 140px;}
    .admin-layout .left .admin-logo .logo img{ width: 100px;}
    .admin-layout .left .admin-logo .logo::before {height: 130px;}
    
    .admin-layout .left { padding: 60px 24px 24px; }
}
@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
    .admin-layout .left { padding: 60px 15px 15px; }

    .inner-container { padding: 25px; }
}
@media (max-width: 1280px) {
    .admin-layout .left ul li a{padding: 5px; font-size: 17px; }
    .admin-layout .left ul li a img { margin-right: 10px; width: 20px; height: 20px; }
}
@media (max-width: 1200px) {
    .admin-layout > .col-left {width: 75px;}
    .admin-layout > .col-right,
    .admin-layout .sidebar.col-right {width: calc(100% - 75px);}
    .admin-layout .sidebar.col-left {width: 250px;}

    .admin-layout .left .admin-logo .logo {display: none;}
    .admin-layout .left .admin-logo .logo::before {content: none;}
    .admin-layout .left ul { margin: auto ;}
    .admin-layout .left .cpy { display: none; }
    .admin-layout .left ul li a {padding-inline: 0;font-size: 0;}
    .admin-layout .left .admin-logo svg,
    .admin-layout .left ul li a img {margin: auto;}
    
    .admin-layout .sidebar .left .admin-logo .logo {display: grid;}
    .admin-layout .sidebar .left .admin-logo .logo::before {content: '';}
    .admin-layout .sidebar .left ul { margin: auto 0 ;}
    .admin-layout .sidebar .left .cpy { display: inline; }
    .admin-layout .sidebar .left ul li a {padding: 5px 10px;font-size: 16px;}
    .admin-layout .sidebar .left .admin-logo svg,
    .admin-layout .sidebar .left ul li a img {margin: 0 15px 0 0;}
    .admin-layout .sidebar .left .admin-logo svg{margin-top: 20px;}
}
@media (max-width: 991px) {
    .admin-layout .left {padding: 50px 15px 15px;}
    .admin-layout .left .admin-logo .logo{width: 120px; height: 120px;}
    .admin-layout .left .admin-logo .logo img{width: 85px;}
    .admin-layout .left .admin-logo .logo::before { height: 110px; }
    .admin-layout .sidebar .left .admin-logo svg{margin-top: 20px;}
    .admin-container{margin: 30px 20px;}

}
@media (max-width: 768px) {
    .inner-container{
        max-width: none;
        width: auto;
        margin: 20px 0;
    }
}
@media (max-width: 600px) {
    .admin-layout .left .admin-logo .logo{width: 100px; height: 100px;}
    .admin-layout .left .admin-logo .logo img{width: 75px;}
    .admin-layout .left .admin-logo .logo::before { height: 95px; }

    .admin-layout > .col-left {width: 60px;}
    .admin-layout > .col-right,
    .admin-layout .sidebar.col-right {width: calc(100% - 60px);}
    .admin-layout .sidebar.col-left {width: 200px;}

    
    /* Admin Footer */
    .admin-layout .admin-footer { padding: 15px 25px;font-size: 16px;line-height: 22px;}
    .inner-container{ padding: 20px; }
}
@media (max-width: 425px) {
    .admin-container {margin: 25px 15px;}
    .inner-container{ padding: 20px 15px; }
}
