.admin-header { background-color: #F5FAFC; border-bottom: 1px solid #E1E1E1; padding: 20px 25px; display: flex; align-items: center; justify-content: space-between; }
.admin-header .title {font-size: 18px;line-height: 1.3;font-weight: 400;color: var(--secondary-color);margin-bottom: 0;}
.admin-header .head-account { display: flex; gap: 10px; align-items: center; }
.admin-header .head-account { font-size: 18px; font-weight: 500; line-height: 30px; color: #1B1B1B; }
.admin-header .head-account img { height: 40px; width: 40px; border-radius: 50%; object-fit: cover;}

.admin-header .currentPage {text-transform: capitalize;} 
.admin-header .currentPage::before {content: ' • ';} 

@media (max-width: 600px) {
    .admin-header {padding: 20px;}
    .admin-header .head-account { gap: 5px; }
    /* .admin-header .title { font-size: 22px; line-height: 32px; } */
    .admin-header .head-account img { height: 30px; width: 30px; }
}
@media (max-width: 425px) {
    .admin-header {padding: 15px;}
    /* .admin-header .title { font-size: 20px; line-height: 24px; } */
    .admin-header .head-account img { height: 25px; width: 25px; }
}