.auth-layout { background: var(--secondary-color) url('../../../public/assets/authBg.jpg') center no-repeat; min-height: 100vh; padding: 30px; background-size: cover; display: grid; place-items: center; }
.auth-layout .auth-container { width: 1000px; min-height: 600px; max-height: 800px; max-width: 100%; background-color: #fff; border-radius: 10px; display: flex; overflow: hidden; }

.auth-layout .auth-container .left { position: relative; width: 25%; background: url('../../../public/assets/authBg2.png') left center no-repeat; background-size: cover;}
.auth-layout .auth-container .left .logo { position: absolute; top: 50%; left: 100%; transform: translate(-50%, -50%); background-color: #F8FCFE; width: 245px; height: 245px; display: grid; place-items: center; border-radius: 100%; }
.auth-layout .auth-container .left .logo img { width: 190px; max-width: 100%; }

.auth-layout .auth-container .right {width: 75%; max-width: 400px; margin: auto;padding: 45px 0 ;}


/* Form CSS */
.auth-form-wrapper .title { font-size: 30px; line-height: 35px; font-weight: 600; color: var(--secondary-color); margin-bottom: 10px; }
.auth-form-wrapper .sub-title { font-size: 18px; line-height: 30px; font-weight: 400; margin-bottom: 25px; }
.auth-form-wrapper .ant-input-affix-wrapper-lg{ padding: 12px 15px; }
.auth-form-wrapper .ant-input{ font-size: 18px !important; line-height: 30px; font-family: var(--main-font); }
.auth-form-wrapper .ant-input-affix-wrapper .ant-input-prefix { margin-inline-end: 10px; color: #979797; }
.auth-form-wrapper .login-form-forgot { font-size: 16px; line-height: 30px; font-weight: 500; color: var(--black-color); text-align: end; display: block; text-decoration: none; }
.auth-form-wrapper .register-msg { font-size: 18px; line-height: 30px; font-weight: 500; text-align: center; margin: 0;}
.auth-form-wrapper .register-msg a { text-decoration: none; color: var(--black-color); }
.auth-form-wrapper .main-button{ display: block; width: 100%; background-color: var(--main-color);border-radius: 100px;font-family: var(--main-font);font-size: 20px;line-height: 30px; padding: 12px 25px; text-transform: none; font-weight: 500; margin-bottom: 24px; }
.auth-form-wrapper .main-button:hover {background-color: var(--main-color-hover);}


/* Regiter */
.auth-form-wrapper .register-form .res-wrap { display: flex; gap: 20px; max-width: 100%; }
.auth-form-wrapper .register-form .res-wrap > div {width: calc(50% - 10px);}
.auth-form-wrapper .register-form .image-wrapper .ant-form-item-control-input-content {display: flex;justify-content: start;align-items: center;gap: 15px;}
.auth-form-wrapper .register-form .image-wrapper .ant-upload-wrapper {width: fit-content;position: relative;}
.auth-form-wrapper .register-form .image-wrapper .ant-upload {overflow: hidden; width: 100px;height: 100px;margin: 0;border: none;}
.ant-upload::before {content: ''; position: absolute;bottom: 0;right: 0;background: var(--main-color) url('../../../public/assets/icons/edit.svg') center no-repeat;padding: 5px;border: 3px solid #fff;line-height: 1;border-radius: 50%;width: 30px;height: 30px;}


/* Forgot Password */
.auth-form-wrapper .forgot-form .button-group{ display: flex; gap: 10px; }
.auth-form-wrapper .forgot-form .main-button{ margin: 0; max-width: 50%;}
.auth-form-wrapper .forgot-form .button-group .cancel{ width: 50%;}
.auth-form-wrapper .forgot-form .button-group .cancel button { width: 100%;border-radius: 100px; text-transform: none; border: 1px solid var(--main-color); color: var(--main-color); font-family: var(--main-font); font-size: 20px; line-height: 30px; padding: 12px 25px; font-weight: 500; }


@media(max-width: 1536px) {
    .auth-form-wrapper .main-button {padding: 8px 25px;font-size: 18px;}

}
@media(max-width: 1440px) {
    .auth-layout .auth-container{min-height: 550px;}
    .auth-form-wrapper .title { font-size: 28px; line-height: 32px; margin-bottom: 5px; }
    .auth-form-wrapper .sub-title { line-height: 24px; margin-bottom: 20px; }
    .auth-form-wrapper .ant-input-affix-wrapper-lg{ padding: 11px 15px; }
    .auth-form-wrapper .ant-form-item {margin-bottom: 20px;}
    .auth-layout .auth-container .left .logo { width: 225px; height: 225px;}
    .auth-layout .auth-container .left .logo img { width: 170px; }
}
@media(max-width: 1366px) {
    .auth-layout .auth-container{min-height: 500px;width: 900px;}
    .auth-layout .auth-container .left .logo { width: 205px; height: 205px;}
    .auth-layout .auth-container .left .logo img { width: 150px; }
    .ant-form-wrapper .main-button {font-size: 16px;line-height: 24px;}

}
@media(max-width: 1280px) {
    .auth-form-wrapper .ant-input-affix-wrapper-lg { padding: 10px 12px; }
    .auth-form-wrapper .ant-input { font-size: 17px !important; }
    .auth-form-wrapper .ant-form-item {margin-bottom: 15px;}
    .auth-layout .auth-container .left .logo { width: 185px; height: 185px;}
    .auth-layout .auth-container .left .logo img { width: 130px; }
    .auth-form-wrapper .register-form .res-wrap { gap: 14px; }
    .auth-form-wrapper .register-form .res-wrap > div {width: calc(50% - 7px);}
}
@media(max-width: 768px) {
    .auth-layout .auth-container {max-width: 90vw;}
    .auth-layout .auth-container .left{display: none;}
    .auth-layout .auth-container .right{max-width: none;}
    .auth-form-wrapper .ant-input { font-size: 16px !important; }
}
@media(max-width: 600px) {
    .auth-form-wrapper .register-form .res-wrap { display: inline; }
    .auth-form-wrapper .register-form .res-wrap > div {width: 100%;}
}
@media(max-width: 425px) {
    .auth-layout {padding: 30px 20px;}
    .auth-layout .auth-container {max-width: 90vw;}
    .auth-layout .auth-container .right{width: 90%; padding: 30px 0;}
}