/* Dashboard - Overview */
#dashboard .overview { background-color: #fff; display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; padding: 30px; margin-bottom: 30px; }
#dashboard .overview .col-left{width: 70%;}
#dashboard .overview .col-right{width: auto;}
#dashboard .overview .col-left .line-2,
#dashboard .overview .col-left .line-2 > div{display: flex; flex-wrap: wrap; align-items: center;}
#dashboard .overview .col-left .line-2{gap: 24px;}
#dashboard .overview .col-left h4 { font-size: 22px; font-weight: 500;margin-bottom: 20px;color: var(--secondary-color);}
#dashboard .overview .col-left h4 span { font-weight: 700;}
#dashboard .overview .col-left h5 { font-size: 18px; font-weight: 700; margin-bottom: 0;margin-right: 6px;color: var(--black-color);}
#dashboard .overview .col-left p {font-size: 18px; font-weight: 400; margin-bottom: 0;}
#dashboard .overview button {text-transform: none;border-radius: 10px;min-width: 180px;font-size: 18px; line-height: 24px; font-family: var(--main-font);height: 54px; color: var(--grey-color); border-color: var(--grey-color);}
#dashboard .overview button img {height: 22px;}

/* Dashboard - Status */
#dashboard .status.active,
#dashboard .status.promo-approved {color: #0070F2; background-color: #CCE2FC; }
#dashboard .status.completed,
#dashboard .status.promo-pending {color: #44A308; background-color: #DAEDCE; }
#dashboard .status.pending,
#dashboard .status.promo-rejected {color: #DF282A; background-color: #F9D4D4; }

/* Dashboard - Promotion Card */
#dashboard .promotion-card { background-color: #fff; border-radius: 10px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; }
#dashboard .promotion-card .promotion-header { display: flex; justify-content: space-between; padding: 15px; border-bottom: 1px solid #E1E1E1; }
#dashboard .promotion-card .promotion-header .promotion-title { font-size: 22px; line-height: 35px; font-weight: 500; color: var(--secondary-color); margin-bottom: 0; }
#dashboard .promotion-card .promotion-header button { font-size: 14px; font-weight: 500; width: 80px; height: fit-content; line-height: 27px; text-align: center; border-radius: 100px; text-transform: none; box-shadow: none; padding: 5px; background-color: var(--main-color); color: #fff; display: flex; gap: 4px; }

#dashboard .promotion-card .promotion-body { display: grid; place-items: center; padding: 60px 15px; }
#dashboard .promotion-card .promotion-body .user-logo { width: 120px; height: auto; aspect-ratio: 1/1; margin: 0 auto 20px; border-radius: 50%; background-color: #eee; object-fit: cover;}
#dashboard .promotion-card .promotion-body h3 { font-size: 24px; font-weight: 500; line-height: 35px; margin-bottom: 10px; color: var(--secondary-color); }
#dashboard .promotion-card .promotion-body h5 { font-size: 18px; font-weight: 400; line-height: 26px; margin-bottom: 20px; word-break: break-word; text-align: center; }

#dashboard .promotion-card .status {border-radius: 25px;padding: 8px 24px; font-size: 14px; text-transform: capitalize; font-weight: 700; margin-bottom: 15px; }

#dashboard .promotion-card .main-button,
.editLisitingModal .main-button,
.promoteNowModal .main-button {width: fit-content; background-color: var(--main-color) !important; color: #fff; border-radius: 100px;font-family: var(--main-font);font-size: 20px;line-height: 30px; padding: 12px 25px; text-transform: none; font-weight: 500; min-width: 270px; box-shadow: none; }
#dashboard .promotion-card .main-button img {width: 30px; margin-right: 10px;}

.editLisitingModal .main-button,
.promoteNowModal .main-button {font-size: 18px;line-height: 26px; padding: 12px 25px; text-transform: none; min-width: 180px; }

/* DashBoard - Promotion Table */
#dashboard .promotion-table { padding: 20px; background-color: #fff; border-radius: 10px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; }
#dashboard .promotion-table .title { font-size: 22px; line-height: 33px; font-weight: 500; margin-bottom: 12px; }
#dashboard .promotion-table .ant-table-wrapper .ant-table {font-family: var(--main-font);font-size: 18px;line-height: 30px;font-weight: 400;color: var(--grey-color);}
#dashboard .promotion-table .ant-table-thead .ant-table-cell { background-color: #F2F7FA; font-weight: 500; color: var(--black-color);}
#dashboard .promotion-table .ant-table-wrapper .ant-table-container tr >*:first-child { border-top-left-radius: 10px; border-bottom-left-radius: 10px; }
#dashboard .promotion-table .ant-table-wrapper .ant-table-container tr >*:last-child { border-top-right-radius: 10px; border-bottom-right-radius: 10px; }
#dashboard .promotion-table .ant-table-row .ant-table-cell:first-child {font-weight: 500;}
#dashboard .promotion-table .ant-table-row .ant-table-cell {border: none;}
#dashboard .promotion-table .ant-table-row:nth-child(even) .ant-table-cell,
#dashboard .promotion-table .ant-table-row:nth-child(even):hover .ant-table-cell {background-color: #F2F7FA;}
#dashboard .promotion-table .ant-table-row:nth-child(odd):hover .ant-table-cell {background-color: inherit;}

#dashboard .promotion-table .status { border-radius: 18px;padding: 10px 24px; font-size: 14px; text-transform: capitalize; font-weight: 700;}

/* Tables Scroll */
#dashboard .promotion-table .ant-table-wrapper .ant-table-content { overflow-x: auto; }
#dashboard .promotion-table .ant-table-wrapper .ant-table-content::-webkit-scrollbar { height: 6px; border-radius: 10px; }
#dashboard .promotion-table .ant-table-wrapper .ant-table-content::-webkit-scrollbar-track { background: #33333311; border-radius: 10px; }
#dashboard .promotion-table .ant-table-wrapper .ant-table-content::-webkit-scrollbar-thumb { background: rgba(51, 51, 51, 0.2); border-radius: 10px; }
#dashboard .promotion-table .ant-table-wrapper .ant-table-content::-webkit-scrollbar-thumb:hover { background: #333333b2; }

/* Image Uploader */
.image-uploader .ant-upload-wrapper {width: fit-content;position: relative;}
.image-uploader .ant-upload {overflow: hidden; width: 100px;height: 100px;margin: 0;border: none;}
.image-uploader .ant-upload::before {content: ''; position: absolute;bottom: 0;right: 0;background: var(--main-color) url('../../../public/assets/icons/edit.svg') center no-repeat;padding: 5px;border: 3px solid #fff;line-height: 1;border-radius: 50%;width: 30px;height: 30px;}

/* editLisitingModal */
.editLisitingModal .ant-modal-content,
.promoteNowModal .ant-modal-content { padding: 0; }
.editLisitingModal .ant-modal-header,
.promoteNowModal .ant-modal-header { border-bottom: 1px solid #e1e1e1; padding: 15px 30px; margin-bottom: 0; }
.editLisitingModal .ant-modal-title,
.promoteNowModal .ant-modal-title { font-size: 24px; font-weight: 700; line-height: 1.3; color: var(--secondary-color); font-family: var(--main-font); }
.editLisitingModal .ant-modal-body,
.promoteNowModal .ant-modal-body { padding: 24px 30px; }
.editLisitingModal .ant-input-lg,
.promoteNowModal .ant-input-lg,
.promoteNowModal .ant-picker { padding: 15px 20px; font-size: 18px; line-height: 30px; font-family: var(--main-font); color: var(--grey-color); }
.promoteNowModal .ant-picker,
.promoteNowModal .ant-select-selector { width: 100%; }
.promoteNowModal .ant-picker input { font-size: 18px; line-height: 30px; }
.promoteNowModal .ant-select {height: auto;}
.promoteNowModal .ant-select .ant-select-selector { font-family: var(--main-font); padding: 15px 20px; font-size: 18px; }

.editLisitingModal .image-uploader .ant-upload{ width: 120px !important; height: 120px !important; }
.promo-type-list { font-family: var(--main-font); }
.promo-type-list .ant-select-item { font-size: 18px; padding: 10px 20px; color: var(--grey-color); }
.ant-select-dropdown  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) { color: var(--black-color); background-color: rgba(0, 0, 0, 0.04); }

.ant-modal-wrap { padding: 50px; }

@media( max-width: 1680px) {
    #dashboard .promotion-card .promotion-body { padding: 50px 15px; }
}
@media (max-width: 1536px) {
    #dashboard .promotion-card .promotion-header {padding: 12px;}
    #dashboard .promotion-card .promotion-header .promotion-title {font-size: 20px;}
    #dashboard .promotion-card .promotion-header button {font-size: 12px;width: 70px; line-height: 24px;}
    #dashboard .promotion-card .promotion-body .user-logo {width: 100px;}
    #dashboard .promotion-card .main-button {min-width: 150px;padding: 8px 25px;font-size: 18px;}
    #dashboard .promotion-card .main-button img { width: 26px; }
    #dashboard .promotion-card .promotion-body { padding: 40px 12px; }

    .editLisitingModal .image-uploader .ant-upload{ width: 100px !important; height: 100px !important; }

    #dashboard .overview {padding: 25px;}
    #dashboard .overview .col-left h4 { font-size: 20px; margin-bottom: 12px; }
    #dashboard .overview button {height: 48px;min-width: 150px;}

}
@media (max-width: 1440px) {
    #dashboard .promotion-card .promotion-header button {width: 60px;padding: 5px 8px;line-height: 20px;}
    #dashboard .promotion-table .ant-table-wrapper .ant-table {line-height: 28px;}
    #dashboard .promotion-table .ant-table-cell {padding: 12px 14px;}

    .editLisitingModal .ant-input-lg,
    .promoteNowModal .ant-input-lg,
    .promoteNowModal .ant-picker { padding: 11px 15px; }
    .editLisitingModal .main-button, .promoteNowModal .main-button { font-size: 16px; line-height: 26px; padding: 8px 25px; min-width: 150px; }
}
@media (max-width: 1366px) {
    #dashboard .promotion-card .promotion-header {padding: 10px;}
    #dashboard .promotion-card .promotion-header .promotion-title { font-size: 18px; }
    #dashboard .promotion-card .promotion-body .user-logo {width: 85px;margin-bottom: 10px;}
    #dashboard .promotion-card .promotion-body h3 {font-size: 20px;margin-bottom: 5px;line-height: 28px;;}
    #dashboard .promotion-card .promotion-body h5 {font-size: 16px;line-height: 20px;}
    #dashboard .promotion-table .ant-table-wrapper .ant-table {font-size: 16px; line-height: 24px;}
    #dashboard .promotion-card .main-button {min-width: 100px;font-size: 16px;line-height: 24px;}


    .editLisitingModal .ant-modal-title, .promoteNowModal .ant-modal-title { font-size: 22px; font-weight: 500; }
    .editLisitingModal .ant-form-item, 
    .promoteNowModal .ant-form-item{margin-bottom: 15px;}
    .editLisitingModal .ant-modal-header, .promoteNowModal .ant-modal-header { padding: 15px 20px; }
    .editLisitingModal .ant-modal-body, .promoteNowModal .ant-modal-body { padding: 24px 20px; }
    .editLisitingModal textarea {height: 90px;} 
    .editLisitingModal .image-uploader .ant-upload{ width: 85px !important; height: 85px !important; }

    #dashboard .overview {padding: 20px;}
    #dashboard .overview .col-left h4 { font-size: 18px; margin-bottom: 10px; }
    #dashboard .overview button {height: 42px;min-width: 135px; font-size: 17px;}
    #dashboard .overview .col-left h5 { font-size: 17px; font-weight: 600; }
    #dashboard .overview .col-left p { font-size: 17px; }
}
@media (max-width: 1280px) {
    #dashboard .promotion-card .main-button{padding: 8px 18px;}
    #dashboard .promotion-card .main-button img { width: 22px; margin-right: 5px; }
}
@media (max-width: 1199px) {
    #dashboard .promotion-card {margin-bottom: 15px;}
    #dashboard .promotion-table .ant-table-cell {min-width: 150px;}
    #dashboard .promotion-table .ant-table-cell:last-child {min-width: 50px;}
}
@media (max-width: 600px) {
    #dashboard .overview .col-left .line-2{gap: 5px;}
    .ant-modal-wrap {
        padding: 0;
    }
}
@media (max-width: 425px) {
    #dashboard .overview .col-left,
    #dashboard .overview .col-right{width: 100%;}
    #dashboard .overview .col-left{margin-bottom: 20px;}
}